import React from "react"
import Navbar from "../components/navbar"
import Layout from "../components/layout"
import Head from "../components/head"
import Button from "../components/button"


export default function NotFound () {

    return (
      <Layout navbar="transparent">
        <Head title="Not found" description="From basic dropshipping & automation to Advanced and custom reporting, we’ve got you covered. Find the right package for your business"/>
        <div className='d-flex justify-content-center align-items-center' style={{height: '100vh', width: '100vw', background: '#3b77ce'}}>
            <div className="text-center my-5 py-5 text-white">
                <p className="feature-highlight__title mb-5">The page was not found!</p>
                <Button style={{ shape: "btn-round btn-round__border", text: "Go home ", href: "/"}}/>
            </div>
        </div>
      </Layout>
    )
}
